<template>
  <div>

    <v-chip
      class="font-weight-bold"
      v-if="detailProgram.title"
      close
      @click:close="clearFilter"
      color="primary">
      {{ detailProgram.title }}
    </v-chip>

    <v-row class="mt-0">
      <!-- <template v-if="isSubscribe == 1">
        <v-col cols="12">
          <div class="d-flex">
            <div class="text-body-1 cl-slate">
              Kelas - kelas yang tersedia dibawah ini dapat Anda akses hingga tanggal
              <span class="font-weight-bold cl-black">{{ formatDate(currentPackage.expired_at) }}</span>
            </div>
            <router-link to="/subscription" class="ml-2 font-weight-bold">
              Lihat paket subskripsi saya
            </router-link>
          </div>
        </v-col>
        <v-col cols="12" v-if="currentPackage.status !== 'active'">
          <v-card class="rounded-lg card-box-shadow">
            <v-card-text>
              <v-row align="center" justify="space-between">
                <div class="pa-3">
                  <div class="text-body-1 cl-black font-weight-bold">
                    Paket berlangganan Anda telah habis
                  </div>
                  <div class="text-body-1 cl-slate">
                    Akses kelas Anda kembali dengan membeli voucher paket berlangganan
                  </div>
                </div>
                <div class="pa-3">
                  <a href="https://shop.yec.co.id/" target="_blank">
                    <v-btn color="primary" elevation="0" rounded>Beli Paket Berlangganan</v-btn>
                  </a>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <template v-if="myProgram.list.length > 0">
          <v-col md="4" lg="3" cols="6" v-for="(list, index) in myProgram.list" :key="index" class="mb-3">
            <v-card elevation="0" class="rounded-lg" @click="goDetailClass(list)" :disabled="list.is_lock">
              <v-img
                height="150"
                :src="list.program.program_information.cover.url"
              ></v-img>
              <div class="pa-4">
                <div class="font-weight-bold mb-1 text-dots-2" style="min-height: 48px;">
                  {{ list.program.title }}
                </div>
                <h3 class="teal--text font-weight-bold mb-2">Rp. {{ formatPrice(list.program.program_information.selling_price) }}</h3>
                <div class="d-flex align-center mb-2">
                  <v-progress-linear
                    class="mr-2"
                    color="primary"
                    background-color="grey lighten-3"
                    height="6"
                    rounded
                    :value="list.results.progress"
                  ></v-progress-linear>
                  <div class="navy-soft--text">{{ list.results.progress }}%</div>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col v-if="myProgram.total >= 12 " md="12" lg="12" cols="12" class="mx-auto text-center py-3">
            <v-btn depressed :loading="btnLoading" color="primary" dark class="rounded-xl" @click="loadMoreData">Tampilkan Lebih Banyak</v-btn> 
          </v-col>
        </template>
        <v-col v-else cols="12" class="d-flex justify-center">
          <div class="text-center">
            <div class="text-h6 cl-black">Anda belum berlangganan</div>
            <div class="pt-4 pb-5 text-body-1 cl-black">
              Untuk mendapatkan akses ke semua kelas, <br>silakan beli paket berlangganan Anda
            </div>
            <a href="https://shop.yec.co.id/" target="_blank">
              <v-btn color="primary" elevation="0" rounded>Beli Paket Berlangganan</v-btn>
            </a>
          </div>
        </v-col>
      </template> -->
      <template>
        <template v-if="myProgram.list.length > 0">
          <v-col md="4" lg="3" cols="6" v-for="(list, index) in myProgram.list" :key="index" class="mb-3">
            <v-hover v-slot="{ hover }">
              <v-card 
                outlined 
                :style="` 
                  position: relative;
                  height: ${$vuetify.breakpoint.smAndUp ? '350px' : '250px'};
                `"
                :elevation="hover ? 12 : 0" class="rounded-lg box-shadow" @click="goDetailClass(list)">
                <v-card-text 
                  class="px-2 py-2">
                  <div
                    class="rounded-lg"
                    :style="`
                      border: 1px solid #D1D5DB;
                      height: ${$vuetify.breakpoint.smAndUp ? '180px' : '80px'};
                      background-size: cover;
                      background-position: center;
                      background-repeat: no-repeat;
                      filter: ${list.is_lock || list.is_disqualified ? 'grayscale(100%)' : ''};
                      background-image: url(${list.program.program_information.cover.url});`
                      /* background-image: url('https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg'); */
                      "></div>
                  
                  <!-- <v-img
                    aspect-ratio="1.7"
                    contain
                    class="rounded-lg"
                    style="border: 1px solid #D1D5DB;"
                    :style="list.is_lock ? 'opacity: 0.5' : ''"
                    :src="list.program.program_information.cover.url">
                  </v-img> -->
                </v-card-text>
                <!-- <div style="position: relative;">
                  <v-img
                    aspect-ratio="1.5"
                    contain
                    class="rounded-lg"
                    :style="list.is_lock ? 'opacity: 0.5' : ''"
                    :src="list.program.program_information.cover.url">
                  </v-img>
                </div> -->
                <!-- <v-card-text class="pa-1 banner-class">
                  <div class="white--text font-weight-bold px-4 subtitle-2 text-no-wrap text-capitalize">
                    {{ list.program.flag ? list.program.flag : 'Bot Class' }}
                  </div>
                </v-card-text> -->
                <div class="px-4">
                  <div class="font-weight-bold text-dots-2 text-wrap mb-1 fs-14">
                    {{ list.program.title }}
                  </div>
                  <div class="font-weight-normal text-capitalize mb-1 text-dots-2 fs-12">
                    By Sistem {{ list.program.flag }}
                  </div>
                  <div class="font-weight-black text-dots-2 text-wrap mb-1 fs-14 primary--text">
                    Rp {{ formatPrice(list.program.program_information.selling_price) }}
                  </div>

                  <div>
                    <v-chip
                      class="mr-2 font-weight-bold"
                      color="error"
                      small
                      v-if="list.is_disqualified"
                    >
                      Terindikasi Kecurangan
                    </v-chip>
                  </div>
                </div>
                <v-card-actions class="px-4" style="position: absolute; bottom: 0; right: 0; left: 0;">
                  <div class="d-flex flex-column mb-4" style="flex: 1">
                    <div class="navy-soft--text text-right fs-12">{{ list.results.progress }}%</div>
                    <v-progress-linear
                      class="mr-2"
                      color="primary"
                      background-color="#E5E7EB"
                      height="6"
                      rounded
                      :value="list.results.progress">
                    </v-progress-linear>
                  </div>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col>
          <v-col v-if="myProgram.total >= 12 && myProgram.limit <= myProgram.total" md="12" lg="12" cols="12" class="mx-auto text-center py-3">
            <v-btn depressed :loading="btnLoading" color="primary" dark class="rounded-xl" @click="loadMoreData">Tampilkan Lebih Banyak</v-btn> 
          </v-col>
        </template>
        <v-col v-else>
          <v-row justify="center">
            <v-col md="4" sm="6">
              <v-img
                class="col-5 mx-auto mt-5 mb-4"
                width="200"
                :src="require('@/assets/images/illustrations/illustration-empty-class.svg')" 
              ></v-img>
              <h6 class="text-h6 text-center mb-5 cl-black">Belum ada kelas yang di ikuti</h6>
            </v-col>
          </v-row>
        </v-col>
      </template>
  
      <v-dialog v-model="dialogCreateLiveness" width="500">
        <v-card>
          <v-card-text class="text-center py-9">
            <img :src="require('@/assets/images/face-recognition.png')" alt="" srcset="" class="mx-auto" width="200">
            <div class="my-4 body-2 text-uppercase font-weight-black">Mohon maaf kelas anda masih terkunci</div>
            <div class="my-4 text-capitalize">Silahkan verifikasi ulang saat ini untuk melanjutkan pengerjaan kelas</div>
            <v-btn 
              class="primary"
              @click="VerifyLivenessNow()">
              Verifikasi Wajah Sekarang
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
  
      <v-snackbar v-model="notification.state" top color="primary" outlined>
        <span v-html="notification.message"></span>
        <template v-slot:action="{ attrs }">
          <v-btn color="primary" icon v-bind="attrs" @click="notification.state = false">
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </div>
</template>

<script>
import { LMS_API } from "@/constants/api"

import moment from "moment";

export default {
  props: ['isSubscribe'],
  emits: ['loadContentTrue', 'loadContentFalse'],
  data() {
    return {
      btnLoading: false,
      dialogCreateLiveness: false,
      detailProgram: {},
      notification: {
        state: false,
        message: ''
      },
      myProgram: {
        search: "",
        list: [],
        total: 0,
        page: 1,
        limit: 12,
        is_complete: null,
        progress_start: 0,
        progress_end: 100,
        is_from_subscribe: 0,
        sort: 'created_at',
        dir: 'desc'
      },
      currentPackage: {}
    }
  },
  methods: {
    formatDate(val) {
      return val ? moment(val).locale('en-gb').format("DD MMMM YYYY") : null;
    },
    async getDetailProgram() {
      await this.axios.get(`${LMS_API.PROGRAM.PUBLIC.DETAIL(this.$route.query.program)}`)
      .then((response) => {
        if (response.status == 200) {
          this.detailProgram = response.data.data;
        }
      })
    },

    searchProgram(val) {
      this.myProgram.search = val
      this.myProgram.sort = 'created_at'
      this.myProgram.dir = 'desc'
      this.myProgram.limit = 12
      this.getClass(true, this.isSubscribe)
    },

    filterByStatus(val) {
      // switch (val) {
      //   case "0":
      //     this.myProgram.progress_start = 0
      //     this.myProgram.progress_end = 100
      //     break;
      //   case "1":
      //     this.myProgram.progress_start = 100
      //     this.myProgram.progress_end = 100
      //     break;
      // }

      this.myProgram.is_complete = val
      this.myProgram.sort = 'created_at'
      this.myProgram.dir = 'desc'
      this.myProgram.limit = 12
      this.getClass(true, this.isSubscribe)
    },

    sortingProgram(val) {
      this.myProgram.sort = 'created_at'
      this.myProgram.dir = val
      this.myProgram.limit = 12
      this.getClass(true, this.isSubscribe)
    },

    clearFilter() {
      this.myProgram.sort = 'created_at'
      this.myProgram.dir = 'desc'
      this.myProgram.limit = 12
      this.detailProgram = {}
      this.$router.push({ name: 'my-class', query: { } })
      this.getClass(true, this.isSubscribe)
    },

    loadMoreData() {
      this.myProgram.limit +=12
      this.getClass(false, this.isSubscribe)
    },

    async getClass(loadContent = true, subscribe = 0) {
      this.btnLoading = true
      if(loadContent) {
        this.$emit('loadContentTrue')
      }
      if (this.isSubscribe == 1) {
        this.getCurrentPackage()
      }

      if(this.$route.query.program) {
        this.getDetailProgram()
      }

      this.myProgram.is_from_subscribe = subscribe
      // ?is_from_subscribe=${this.myProgram.is_from_subscribe}&sort=${this.myProgram.sort}&dir=${this.myProgram.dir}&limit=${this.myProgram.limit}
      await this.axios
      .get(`${LMS_API.PROGRAM.LIST}`, {
        params: {
          limit: this.myProgram.limit,
          // limit: this.myProgram.limit,
          // is_from_subscribe: this.myProgram.is_from_subscribe,
          program_name: this.myProgram.search,
          is_from_subscribe: 0,
          progress_start: 0,
          progress_end: 0,
          sort: this.myProgram.sort,
          dir: this.myProgram.dir,
          program_id: this.$route.query.program,
          is_complete: this.myProgram.is_complete,
        }
      })
      .then((response) => {
        let res = response.data.data;
        this.$emit('loadContentFalse')
        if (response.status == 200) {
          this.myProgram.list = res.list;
          this.myProgram.total = res.total;
          this.btnLoading = false
        }
      });
    },
    getCurrentPackage() {
      this.axios.get(`${LMS_API.SUBSCRIBE.CURRENT_PACKAGE}`)
      .then((response) => {
        if (response.status == 200) {
          this.currentPackage = response.data.data;
        } else {
          this.currentPackage = {}
        }
      })
    },
    formatPrice(value) {
      let val = (value/1).toFixed(0).replace('.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    createLiveness() {
      this.dialogCreateLiveness = true
    },
    VerifyLivenessNow() {
      this.axios.post(`${LMS_API.LIVENESS.CREATE}`)
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.dialogCreateLiveness = false

          window.open(res.data.url, '_blank')
        }
      }).catch((error) => {
        if(error.response.status == 400) {
          this.dialogCreateLiveness = false
          this.notification.state = true
          this.notification.message = error.response.data.message
        }
      })
    },
    goDetailClass(list) {
      if(list.is_lock || list.is_disqualified) {
        return false
      }

      this.$store.commit('SET_PROGRAM_NAME', list.program.title)
      localStorage.setItem('sistem_program_name', list.program.title)

      if(list.program.flag != 'sertifikasi') {
        // var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") > -1) {
          this.$router.push({
            name: "detail-class",
            params: { id: list.id },
          });
        } else {
          // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream){
          //   this.$router.push({ name: 'detail-class-v2', params: { id: list.id } })
          // } else {
          //   this.$router.push({ name: 'detail-class-v2', params: { id: list.id } })
          // }
          this.$router.push({ name: 'detail-class', params: { id: list.id } })
        }
      }else {
        this.$router.push({ name: 'checkpoint', params: { id: list.id } })
      }

    },
  }
}
</script>

<style>
.banner-class {
  background: #3600e6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #09097993, #1A56DB);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #09097993, #1A56DB); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>