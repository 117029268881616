import Vue from 'vue'
import VueRouter from 'vue-router'
import Views from '../views'
import store from '../store'


Vue.use(VueRouter)

let routes = {
	auth: [
		{
      path: "/checkpoint/:id/start",
      name: "checkpoint",
      component: Views.User.Checkpoint.EpotPreparation,
    },
    {
      path: "/checkpoint/:id/disclaimer",
      name: "checkpoint-disclaimer",
      component: Views.User.Checkpoint.EpotSpecification,
    },
    {
      path: "/checkpoint/:id/disclaimer",
      name: "checkpoint-specification",
      component: Views.User.Checkpoint.EpotSpecification,
    },
    {
      path: "/checkpoint/:id/device",
      name: "checkpoint-device",
      component: Views.User.Checkpoint.EpotDeviceVerification,
    },
		{ path: '/dashboard', name: 'beranda', component: Views.User.Dashboard },
		// { path: '/dashboard', name: 'beranda', redirect: '/class' },
		{ path: '/studi', name: 'studi', component: Views.User.Home.StudiUser },
		{ path: '/account', name: 'account', component: Views.User.Account.AccountSetting },
		{ path: '/account/edit', name: 'account-edit', component: Views.User.Account.AccountUpdate },
		{ path: '/account/password', name: 'account-password', component: Views.User.Account.AccountPassword },
		{ path: '/account/resume', name: 'account-resume', component: Views.User.Account.AccountResume },
		{ path: '/subscription', name: 'subscription', component: Views.User.Subscription.Subscription },

		// JOBS
		{ path: '/jobs', name: 'jobs', component: Views.User.Jobs.JobsView },
		{ path: '/jobs/:id', name: 'jobs-detail', component: Views.User.Jobs.JobsDetail },

		// COMPANY
		{ path: '/company', name: 'company', component: Views.User.Company.Company },
		{ path: '/company/:id', name: 'company-detail', component: Views.User.Company.CompanyDetail },

		// APPLYMENT
		{ path: '/application', name: 'applyment', component: Views.User.Applyment.Applyment },
		{ path: '/application/:id', name: 'applyment-detail', component: Views.User.Applyment.ApplymentDetail },

		{ path: '/detail-kelas-user/:id', name: 'detail-kelas-user', component: Views.User.Home.DetailUser },
		{ path: '/disqualified', name: 'disqualified', component: Views.User.MyClass.Disqualified },
		{ path: '/class', name: 'my-class', component: Views.User.MyClass.MyClass },
		{ path: '/class/:id', name: 'detail-class', component: Views.User.MyClass.DetailClass },
		{ path: '/class-v2/:id', name: 'detail-class-v2', component: Views.User.MyClass.DetailClassV2 },


		{ path: '/prakerja/callback', name: 'home', component: Views.Public.PublicCallback },
	],
	guest: [
		{ path: '/', name: 'home', redirect: "/auth/login" },
		{ path: '/auth/login', name: 'login', component: Views.Auth.Login },
		// { path: '/auth/registration', name: 'registration', component: Views.Auth.Registration },
		// { path: '/auth/registration/company', name: 'registration-company', component: Views.Auth.RegistrationCompany },
		{ path: '/auth/set-password/:token', name: 'set-password', component: Views.Auth.SetPassword },
		{ path: '/auth/forgot-password', name: 'forgot-password', component: Views.Auth.ForgotPassword },

		{ path: '/share-jobs/:id', name: 'jobs-detail', component: Views.User.Jobs.JobsDetail },
	],
	default: [
		// { path: '/certificate-participant/:id', name: 'certificate-participant', component: Views.User.Certif.CertifPart },
		{ path: '/certificate-participant/:id', name: 'certificate-participant', component: Views.User.Certif.CertifPartNew },
		// { path: '/certificate-graduation/:id', name: 'certificate-graduation', component: Views.User.Certif.CertifGrad },
		{ path: '/certificate-graduation/:id/:license', name: 'certificate-graduation', component: Views.User.Certif.CertifComp },
		{ path: '/maintenance', name: 'maintenance', component: Views.Maintenance },
		{ path: '*', name: 'not-found', component: Views.Errors.NotFound },
	]
}

routes.auth.forEach(route => { route.meta = { ...route.meta, auth: true } });
routes.guest.forEach(route => { route.meta = { ...route.meta, guest: true } });
routes = [
	...routes.auth,
	...routes.guest,
	...routes.default,
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	const under_maintenance = store.state.company.under_maintenance
	const phone_number = store.state.company.phone_number

	if (under_maintenance) {
		if (to.path !== "/maintenance") {
			if (store.getters.isLoggedIn) {
				if (phone_number.includes(store.getters.user.phone)) {
					if (to.meta.auth) {
						next()
					}
					if (to.path == '/' || to.meta.guest) {
						next('/dashboard')
					}
				} else {
					next('/maintenance')
				}
			} else {
				next('/maintenance')
			}
		} else {
			next()
		}
	} else {
		if (to.path == '/maintenance') {
			next('/')
		} else {
			next()
		}
		if (store.getters.isLoggedIn) {
			// this.user.is_verified && this.user.fullname && this.user_sistem.talent_first_name
			// if (store.getters.user.is_updated_profile === false && to.path != '/account/edit') {
			// if (store.getters.user_sistem.first_name && to.path != '/account/edit') {
			if (store.getters.user.is_updated_profile === false && to.path != '/account/edit') {
				next('/account/edit')
			}
			if (to.path == '/' || to.meta.guest) {
				next('/dashboard')
			}
		}
		if (to.meta.auth && !store.getters.isLoggedIn) {
			localStorage.setItem('sistem_redirect', location.pathname)
			next('/')
		}
	}
})

export default router
