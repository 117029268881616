<template>
  <!-- ===== Pengalaman Kerja ===== -->
  <v-card outlined flat class="mt-4 rounded-lg">
    <v-card-title>
      Pengalaman Kerja 
      <v-spacer></v-spacer>
      <v-btn 
        v-if="!showWorkExperience" 
        color="#1F2A37"
        style="border: 1px solid #E5E7EB"
        class="rounded-lg"
        x-small
        outlined
        @click="onOpenForm()">
        Tambah
      </v-btn>
      <div v-else>
        <v-btn 
          color="primary"
          x-small
          depressed
          class="rounded-lg"
          @click="state_form == 'create' ? onAddWorkExperiences() : onUpdateWorkExperiences()">
          Simpan
        </v-btn>
        <v-btn 
          color="#1F2A37"
          style="border: 1px solid #E5E7EB"
          class="ml-2 rounded-lg"
          x-small
          outlined
          @click="onOpenForm()">
          Tutup
        </v-btn>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-show="!showWorkExperience">

      <div class="text-left" v-if="profileData.length > 0">

        <v-row v-for="(workExperience, index) in profileData" :key="index">
          <v-col cols="3">
            <div class="black--text">{{ convertDateToMonth(workExperience.talent_work_experience_start_date) }} - {{ workExperience.talent_work_experience_is_currently_working ? "Saat ini" : convertDateToMonth(workExperience.talent_work_experience_end_date) }}</div>
          </v-col>
          <v-col cols="7" class="text-left">
            <div class="font-weight-medium black--text">{{ workExperience.talent_work_experience_company_name }}</div>
            <div class="black--text">{{ workExperience.talent_work_experience_position }}</div>
            <!-- <div class="black--text">{{ workExperience.talent_work_experience_description }}</div> -->
          </v-col>
          <v-col cols="2" class="justify-end text-right d-flex align-end">
            <v-btn
              color="#1F2A37"
              class="rounded-lg"
              x-small
              icon
              @click="onOpenEditWorkExperiences(index)">
              <v-icon size="20">ri-pencil-line</v-icon>
            </v-btn>
            <v-btn
              color="#1F2A37"
              class="rounded-lg"
              x-small
              icon
              @click="onDeleteWorkExperiences(index)">
              <v-icon size="20">ri-delete-bin-7-line</v-icon>
            </v-btn>
          </v-col>
        </v-row>

      </div>

      <div class="text-center" v-else>
        Beritahu pengalaman pekerjaan Anda sebagai pembeda keunggulan Anda
      </div>

    </v-card-text>
    <v-expand-transition>
      <v-card-text v-show="showWorkExperience">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>

          <div>
            <div class="mb-1 font-weight-medium">Nama Perusahaan</div>
            <v-text-field
              outlined
              class="rounded-lg"
              dense
              :rules="[rules.required]"
              v-model="talent_work_experiences.talent_work_experience_company_name"
              placeholder="Tuliskan Nama Perusahaan">
            </v-text-field>
          </div>
  
          <div>
            <div class="mb-1 font-weight-medium">Jabatan</div>
            <v-text-field
              outlined
              class="rounded-lg"
              dense
              :rules="[rules.required]"
              v-model="talent_work_experiences.talent_work_experience_position"
              placeholder="Tuliskan Jabatan">
            </v-text-field>
          </div>
  
          <v-row>
            <v-col>
              <div>
                <div class="mb-1 font-weight-medium">Bulan dan tahun masuk</div>
                <v-menu
                  v-model="talent_work_experience_start_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-on="on"
                      v-bind="attrs"
                      class="rounded-lg"
                      dense
                      readonly
                      :rules="[rules.required]"
                      v-model="talent_work_experiences.talent_work_experience_start_date"
                      placeholder="Pilih Tahun Masuk">
                    </v-text-field>
                  </template>
                  <v-date-picker
                    type="month"
                    v-model="talent_work_experiences.talent_work_experience_start_date"
                    @input="talent_work_experience_start_date = false">
                  </v-date-picker>
                </v-menu>
              </div>
            </v-col>
            <v-col>
              <div>
                <div class="mb-1 font-weight-medium">Bulan dan tahun selesai</div>
                <v-menu
                  v-model="talent_work_experience_end_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-on="on"
                      v-bind="attrs"
                      class="rounded-lg"
                      dense
                      :hide-details="talent_work_experiences.talent_work_experience_is_currently_working"
                      :rules="talent_work_experiences.talent_work_experience_is_currently_working ? [] : [rules.required]"
                      :readonly="talent_work_experiences.talent_work_experience_is_currently_working"
                      :disabled="talent_work_experiences.talent_work_experience_is_currently_working"
                      v-model="talent_work_experiences.talent_work_experience_end_date"
                      placeholder="Pilih Tahun Selesai">
                    </v-text-field>
                  </template>
                  <v-date-picker
                    type="month"
                    :min="talent_work_experiences.talent_work_experience_start_date"
                    v-model="talent_work_experiences.talent_work_experience_end_date"
                    @input="talent_work_experience_end_date = false">
                  </v-date-picker>
                </v-menu>
  
                <v-checkbox
                  dense
                  @change="() => talent_educations.talent_education_end_date = ''"
                  v-model="talent_work_experiences.talent_work_experience_is_currently_working"
                  label="Masih bekerja di perusahaan ini">
                </v-checkbox>
              </div>
            </v-col>
          </v-row>
  
          <div>
            <div class="mb-1 font-weight-medium">Deskripsi Pekerjaan</div>
            <v-textarea
              outlined
              auto-grow
              placeholder="Tuliskan Deskripsi Pekerjaan"
              :rules="[rules.required]"
              v-model="talent_work_experiences.talent_work_experience_description"
              class="rounded-lg"
              rows="6">
            </v-textarea>
          </div>
          
        </v-form>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    profileData: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    valid: true,
    showWorkExperience: false,

    rules: {
      required: value => !!value || 'field tidak boleh kosong.',
    },

    state_index: 0,
    state_id: "",
    state_form: "create",

    // MENU DATE PICKER WORK EXPERIENCE
    talent_work_experience_start_date: false,
    talent_work_experience_end_date: false,

    talent_work_experiences: {
      talent_work_experience_company_name: "",
      talent_work_experience_position: "",
      talent_work_experience_start_date: "",
      talent_work_experience_end_date: "",
      talent_work_experience_description: "",
      talent_work_experience_is_currently_working: true
    },
  }),
  methods: {
    convertDateToMonth(date) {
      return date ? moment(date).locale("id").format("MMMM YYYY") : "No Date"
    },

    onOpenForm() {
      this.showWorkExperience = !this.showWorkExperience

      this.talent_work_experiences = {
        talent_work_experience_company_name: "",
        talent_work_experience_position: "",
        talent_work_experience_start_date: "",
        talent_work_experience_end_date: "",
        talent_work_experience_description: "",
        talent_work_experience_is_currently_working: true
      }

      this.$refs.form.resetValidation()
    },

    onValidation() {
      let isValid = true

      if(!this.talent_work_experiences.talent_work_experience_is_currently_working) {
        if(this.talent_work_experiences.talent_work_experience_end_date == "") {
          isValid = false
        }
      }

      return isValid
    },
    
    // ====================================================================================
    // WORK EXPERIENCE FUNCTIONS
    // Work Experience Add
    async onAddWorkExperiences() {
      
      if(!this.$refs.form.validate() || this.onValidation() === false) return
        
      let talentWorkExpereinces = this.profileData

      if(this.talent_work_experiences.talent_work_experience_company_name) {
        talentWorkExpereinces.push(this.talent_work_experiences)
      }

      this.profileData = talentWorkExpereinces

      this.$emit("save", talentWorkExpereinces)

      this.talent_work_experiences = {
        talent_work_experience_company_name: "",
        talent_work_experience_position: "",
        talent_work_experience_start_date: "",
        talent_work_experience_end_date: "",
        talent_work_experience_description: "",
        talent_work_experience_is_currently_working: true
      }

      this.showWorkExperience = false
      
      // await this.onSaveTalentProfileResume()

      // this.$refs.snackbar.open('success', 'Pengalaman kerja berhasil ditambahkan!')
    },

    // Work Experience Edit
    async onOpenEditWorkExperiences(index) {
      let talentWorkExpereinces = this.profileData[index]

      this.talent_work_experiences = {
        talent_work_experience_company_name: talentWorkExpereinces.talent_work_experience_company_name,
        talent_work_experience_position: talentWorkExpereinces.talent_work_experience_position,
        talent_work_experience_start_date: moment(talentWorkExpereinces.talent_work_experience_start_date).format("YYYY-MM"),
        talent_work_experience_end_date: talentWorkExpereinces.talent_work_experience_end_date ? moment(talentWorkExpereinces.talent_work_experience_end_date).format("YYYY-MM") : "",
        talent_work_experience_description: talentWorkExpereinces.talent_work_experience_description,
        talent_work_experience_is_currently_working: talentWorkExpereinces.talent_work_experience_is_currently_working
      }

      this.state_index = index

      this.showWorkExperience = true

      this.state_form = "edit"
    },

    // Work Experience Update
    async onUpdateWorkExperiences() {

      if(!this.$refs.form.validate() || this.onValidation() === false) return
      
      let talentWorkExpereinces = this.profileData

      talentWorkExpereinces[this.state_index] = this.talent_work_experiences

      this.profileData = talentWorkExpereinces

      this.$emit("save", talentWorkExpereinces)

      this.talent_work_experiences = {
        talent_work_experience_company_name: "",
        talent_work_experience_position: "",
        talent_work_experience_start_date: "",
        talent_work_experience_end_date: "",
        talent_work_experience_description: "",
        talent_work_experience_is_currently_working: true
      }

      // this.$refs.snackbar.open('success', 'Pengalaman kerja berhasil diubah!')

      this.showWorkExperience = false

      this.state_form = "create"
      this.state_index = 0
    },

    // Work Experience Delete
    async onDeleteWorkExperiences(index) {
      let talentWorkExpereinces = [...this.profileData]

      talentWorkExpereinces.splice(index, 1)

      this.profileData = talentWorkExpereinces

      // this.$refs.snackbar.open('success', 'Pengalaman kerja berhasil dihapus!')

      this.$emit("save", talentWorkExpereinces)
      // await this.onSaveTalentProfileResume()

      
      this.showWorkExperience = false

    },
  },
}
</script>