<template>
  <v-card elevation="0" class="px-2 transparent" @click="showCertif">
    <!-- 
    $vuetify.breakpoint.name == 'xs' ? true : false
    
    :enable-download="false" 
    :preview-modal="true" 
    :show-layout="false" 
    -->
    <vue-html2pdf 
      ref="certifPeserta" 
      :enable-download="false" 
      :preview-modal="true" 
      :show-layout="false" 
      :pdf-quality="2"
      :filename="certificateName" 
      pdf-orientation="landscape" 
      :manual-pagination="true" 
      pdf-content-width="100%"
      pdf-format="legal"
      @hasDownloaded="hasGenerated($event)">
      <section slot="pdf-content" v-if="data.program_flag == 'mini bootcamp'">
        <section class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/bg-certif-mini-bootcamp.jpg')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `810px`,
          color: `#4B4B4B`,
        }">
          <div class="style1" v-if="Object.keys(data).length > 0">
            <div 
              style="
                font-style: normal;
                text-align: left;
                font-family: Inter, sans-serif;
                font-weight: 900;
                font-size: 40px;
                line-height: auto;
                margin-top: 21%;
                margin-left: 8%;
                color: black;
              ">
              {{ data.user_name }}
            </div>

            <div 
              style="
                font-style: normal;
                font-family: Inter, sans-serif;
                font-weight: 700;
                font-size: 26px;
                line-height: 20px;
                margin-top: 8%;
                margin-left: 8%;
                color: black;
              ">
              {{ data.program_name }}
            </div>
          </div>
        </section>
      </section>
      <section slot="pdf-content" v-else-if="data.program_flag == 'bootcamp'">
        <template v-if="data.program_name === '[SISTEM x SIF] Frontend Developer'">
          <section
            class="pdf-item"
            :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/SIF_certificate.jpg')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `815px`,
              color: `#4B4B4B`,
            }">
            <div class="style1" v-if="Object.keys(data).length > 0">
              <div 
                style="
                  font-style: normal;
                  text-align: left;
                  font-family: Inter, sans-serif;
                  font-weight: 900;
                  font-size: 40px;
                  line-height: auto;
                  margin-top: 17%;
                  margin-left: 18%;
                  color: black;
                ">
                {{ data.user_name }}
              </div>
  
              <div 
                style="
                  font-style: normal;
                  font-family: Inter, sans-serif;
                  font-weight: 700;
                  font-size: 26px;
                  line-height: 20px;
                  margin-top: 6%;
                  margin-left: 18%;
                  color: black;
                ">
                {{ data.program_name }}
              </div>
            </div>
          </section>
          <section
            class="pdf-item"
            :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/SIF_certificate2.jpg')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `815px`,
              color: `#000000`,
            }">
            <div style="padding-top: 180px">
              <div 
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                ">
                <div style="width: 60%;">
                  <div
                    style="
                      font-family: Inter, sans-serif;
                      font-weight: normal;
                      font-weight: bold;
                      font-size: 16px;
                      line-height: 24px;
                      text-align: start;
                      margin: 25px 0;
                    "
                  >
                    Program Pelatihan : {{ data.program_name }}
                  </div>
                  <table style="width: 100%; border-collapse: collapse; background-color: aliceblue; font-family: Inter, sans-serif;">
                    <thead>
                      <tr>
                        <th rowspan="2" style="border: 1px solid; text-align: center; padding: 12px 0;">
                          <div style="font-size: 16px; text-transform: uppercase;">No</div>
                        </th>
                        <th rowspan="2" style="border: 1px solid; text-align: center; padding: 12px 0;">
                          <div style="font-size: 16px; text-transform: uppercase;">Unit Kompetensi</div>
                        </th>
                        <th rowspan="2" style="border: 1px solid; text-align: center; padding: 12px 0;">
                          <div style="font-size: 16px; text-transform: uppercase;">Nilai</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="result_score.activities.length > 0" style="font-size: 15px">
                      <tr v-for="(activity, i) in result_score.activities" :key="i">
                        <td style="border: 1px solid; text-align: center">{{ i + 1 }}</td>
                        <td style="border: 1px solid; text-align: start; padding: 8px">{{ activity.title }}</td>
                        <td style="border: 1px solid; text-align: center; padding: 8px">{{ activity.score }}</td>
                      </tr>
                      <tr>
                        <td colspan="2" style="border: 1px solid; text-align: right; padding: 8px">
                          <div style="font-size: 15px; font-weight: bold">Total Score</div>
                        </td>
                        <td style="border: 1px solid; text-align: center; padding: 8px">
                          <div style="font-size: 15px; font-weight: bold">{{ result_score.total_score }}</div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" style="border: 1px solid; text-align: right; padding: 8px">
                          <div style="font-size: 15px; font-weight: bold">Nilai rata-rata</div>
                        </td>
                        <td style="border: 1px solid; text-align: center; padding: 8px">
                          <div style="font-size: 15px; font-weight: bold">{{ result_score.average }}</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- <div style="display: flex; justify-content: space-between; margin-top: 10px;">
                    <div>
                      <div
                        style="
                          font-family: Roboto;
                          font-style: normal;
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 16px;
                          text-transform: uppercase;
                          text-align: start;
                          margin-bottom: 10px;
                          margin-top: 10px;
                        "
                      >
                        Keterangan Nilai :
                        <div style="padding-top: 10px">
                          <div style="padding-top: 4px">Baik Sekali : 90 - 100</div>
                          <div style="padding-top: 4px">Baik : 80 - 89</div>
                          <div style="padding-top: 4px">Cukup : 70 - 79</div>
                          <div style="padding-top: 4px">Kurang : 50 - 69</div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </section>
        </template>
        <template v-if="data.program_name === '[SISTEM x D3 TI UNS] Frontend Developer'">
          <section
            class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/bg-certif-uns.jpg')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `810px`,
            color: `#4B4B4B`,
          }">
            <div class="style1" v-if="Object.keys(data).length > 0">
              <div 
                style="
                  font-style: normal;
                  text-align: left;
                  font-family: 'Urbanist', sans-serif;
                  font-weight: 500;
                  font-size: 17px;
                  line-height: auto;
                  margin-top: 6%;
                  margin-left: 30%;
                  color: black;
                ">
                {{ data.license }}/SIS/BC/XI/2024
              </div>
              <div 
                style="
                  font-style: normal;
                  text-align: left;
                  font-family: 'Urbanist', sans-serif;
                  font-weight: 700;
                  font-size: 40px;
                  line-height: auto;
                  margin-top: 5%;
                  margin-left: 17%;
                  color: black;
                ">
                {{ data.user_name }}
              </div>
            </div>
          </section>
          <section
            class="pdf-item"
            :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/bg-certif-uns-back.jpg')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `815px`,
              color: `#000000`,
            }">
            <div style="padding-top: 180px">
              <div 
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                ">
                <div style="width: 60%;">
                  <div
                    style="
                      font-family: 'Urbanist', sans-serif;
                      font-weight: normal;
                      font-weight: 500;
                      font-size: 16px;
                      line-height: 24px;
                      text-align: start;
                      margin: 10px 0;
                    "
                  >
                    <!-- Program Pelatihan : {{ data.program_name }} -->
                    Struktur Program Bootcamp FE Intermediate SISTEM x D3TI UNS dengan topik:
                  </div>
                  <table style="width: 100%; border-collapse: collapse; background-color: aliceblue; font-family: 'Urbanist', sans-serif;">
                    <thead>
                      <tr>
                        <th rowspan="2" style="border: 1px solid; text-align: center; padding: 12px 0;">
                          <div style="font-size: 16px; text-transform: uppercase;">No</div>
                        </th>
                        <th rowspan="2" style="border: 1px solid; text-align: center; padding: 12px 0;">
                          <div style="font-size: 16px; text-transform: uppercase;">Unit Kompetensi</div>
                        </th>
                        <th rowspan="2" style="border: 1px solid; text-align: center; padding: 12px 0;">
                          <div style="font-size: 16px; text-transform: uppercase;">Nilai</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="result_score.activities.length > 0" style="font-size: 15px">
                      <tr v-for="(activity, i) in result_score.activities" :key="i">
                        <td style="border: 1px solid; text-align: center">{{ i + 1 }}</td>
                        <td style="border: 1px solid; text-align: start; padding: 8px">{{ activity.title }}</td>
                        <td style="border: 1px solid; text-align: center; padding: 8px">{{ activity.score }}</td>
                      </tr>
                      <tr>
                        <td colspan="2" style="border: 1px solid; text-align: right; padding: 8px">
                          <div style="font-size: 15px; font-weight: bold">Total Score</div>
                        </td>
                        <td style="border: 1px solid; text-align: center; padding: 8px">
                          <div style="font-size: 15px; font-weight: bold">{{ result_score.total_score }}</div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" style="border: 1px solid; text-align: right; padding: 8px">
                          <div style="font-size: 15px; font-weight: bold">Nilai rata-rata</div>
                        </td>
                        <td style="border: 1px solid; text-align: center; padding: 8px">
                          <div style="font-size: 15px; font-weight: bold">{{ result_score.average }}</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div style="display: flex; justify-content: space-between; margin-top: 10px;">
                    <div>
                      <div
                        style="
                          font-family: 'Urbanist', sans-serif;
                          font-style: normal;
                          font-size: 15px;
                          font-weight: 500;
                          line-height: 16px;
                          text-transform: uppercase;
                          text-align: start;
                          margin-bottom: 10px;
                          margin-top: 10px;
                        "
                      >
                        Catatan:
                        Keterangan Nilai:
                        <div style="padding-top: 10px">
                          <div style="padding-top: 4px">91 - 100 = Sangat Mampu/Sangat Baik</div>
                          <div style="padding-top: 4px">81 - 90  = Mampu/Baik</div>
                          <div style="padding-top: 4px">71 - 80  = Cukup</div>
                          <div style="padding-top: 4px">51 - 70  = Tidak Mampu/Buruk</div>
                          <div style="padding-top: 4px">0 - 50   = Sangat Tidak Mampu/Sangat Buruk</div>
                        </div>
                      </div>
                    </div>
                    <div style="font-family: 'Urbanist', sans-serif; font-weight: 500; font-size: 15px;">
                      <div>Terbit pada 3 November 2024</div>
                      <div>Berlaku hingga 3 November 2025</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </template>
        <section 
          v-else
          class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/bg-certif-bootcamp.jpg')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `810px`,
          color: `#4B4B4B`,
        }">
          <div class="style1" v-if="Object.keys(data).length > 0">
            <div 
              style="
                font-style: normal;
                text-align: left;
                font-family: Inter, sans-serif;
                font-weight: 900;
                font-size: 40px;
                line-height: auto;
                margin-top: 17%;
                margin-left: 18%;
                color: black;
              ">
              {{ data.user_name }}
            </div>

            <div 
              style="
                font-style: normal;
                font-family: Inter, sans-serif;
                font-weight: 700;
                font-size: 26px;
                line-height: 20px;
                margin-top: 7%;
                margin-left: 18%;
                color: black;
              ">
              {{ data.program_name }}
            </div>
          </div>
        </section>
      </section>
      <section slot="pdf-content" v-else-if="data.program_flag == 'talent development solutionlab'">
        <section class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/TES_SLABS.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `810px`,
          color: `#4B4B4B`,
        }">
          <div class="style1" v-if="Object.keys(data).length > 0">
            <div 
              style="
                font-style: normal;
                text-align: left;
                font-family: Inter, sans-serif;
                font-weight: 500;
                font-size: 38px;
                line-height: auto;
                margin-top: 16%;
                margin-left: 7%;
                color: #3A3A3A;
              ">
              {{ data.user_name }}
            </div>
            <div 
              style="
                font-style: normal;
                text-align: left;
                font-family: Inter, sans-serif;
                font-weight: 500;
                font-size: 18px;
                line-height: auto;
                margin-top: 12%;
                margin-left: 50.8%;
                color: #474747
              ">
              {{ EndDateTestTDS }}
            </div>
          </div>
        </section>
      </section>
      <section slot="pdf-content" v-else-if="data.program_flag == 'webinar'">
        <section class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/webinar_digital_marketing_and_social_media.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `810px`,
          color: `#4B4B4B`,
        }">
          <div class="style1" v-if="Object.keys(data).length > 0">
            <div 
              style="
                font-style: normal;
                text-align: center;
                font-family: Inter, sans-serif;
                font-weight: 600;
                font-size: 40px;
                line-height: auto;
                margin-top: 22%;
                color: #3A3A3A;
              ">
              {{ data.user_name }}
            </div>
          </div>
        </section>
      </section>
      <section slot="pdf-content" v-else-if="data.program_flag == 'try out'">
        <template v-if="data.program_name.trim() === '[TRY OUT - CAM]: RBB (Rekruitment Bersama BUMN) 2025'">
          <section class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/bg-certif-to-bumn.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `810px`,
            color: `#4B4B4B`,
          }">
            <div class="style1" v-if="Object.keys(data).length > 0">
              <div 
                style="
                  font-style: normal;
                  text-align: center;
                  font-family: Inter, sans-serif;
                  font-weight: 600;
                  font-size: 40px;
                  line-height: auto;
                  margin-top: 22%;
                  color: #3A3A3A;
                ">
                {{ data.user_name }}
              </div>
              <div 
                style="
                  font-style: normal;
                  text-align: center;
                  font-family: Inter, sans-serif;
                  font-weight: 600;
                  font-size: 20px;
                  letter-spacing: 2px;
                  line-height: auto;
                  margin-top: 9%;
                  color: #343B6D;
                  /* color: #272E66; */
                ">
                {{ endDateTryoutTest }}
              </div>
            </div>
          </section>
        </template>
        <template v-if="data.program_name.trim() === 'TRY OUT : RBB (Rekruitment Bersama BUMN) 2025'">
          <section class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/bg-certif-to-bumn.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `810px`,
            color: `#4B4B4B`,
          }">
            <div class="style1" v-if="Object.keys(data).length > 0">
              <div 
                style="
                  font-style: normal;
                  text-align: center;
                  font-family: Inter, sans-serif;
                  font-weight: 600;
                  font-size: 40px;
                  line-height: auto;
                  margin-top: 22%;
                  color: #3A3A3A;
                ">
                {{ data.user_name }}
              </div>
              <div 
                style="
                  font-style: normal;
                  text-align: center;
                  font-family: Inter, sans-serif;
                  font-weight: 600;
                  font-size: 20px;
                  letter-spacing: 2px;
                  line-height: auto;
                  margin-top: 9%;
                  color: #343B6D;
                  /* color: #272E66; */
                ">
                {{ endDateTryoutTest }}
              </div>
            </div>
          </section>
        </template>
        <template v-if="data.program_name.trim() === '[Try Out] Sukses UTBK-SNBT 2025 - Paket 1'">
          <section class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/bg-certif-utbk-snbt.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `810px`,
            color: `#4B4B4B`,
          }">
            <div class="style1" v-if="Object.keys(data).length > 0">
              <div 
                style="
                  font-style: normal;
                  text-align: center;
                  font-family: Inter, sans-serif;
                  font-weight: 600;
                  font-size: 40px;
                  line-height: auto;
                  margin-top: 22%;
                  color: #3A3A3A;
                ">
                {{ data.user_name }}
              </div>
              <div 
                style="
                  font-style: normal;
                  text-align: center;
                  font-family: Inter, sans-serif;
                  font-weight: 600;
                  font-size: 20px;
                  letter-spacing: 2px;
                  line-height: auto;
                  margin-top: 9%;
                  color: #343B6D;
                  /* color: #272E66; */
                ">
                {{ endDateTryoutTest }}
              </div>
            </div>
          </section>
        </template>
        <template v-if="data.program_name.trim() === '[Try Out] Sukses UAS SMA IPA 2025 - Paket 1'">
          <section class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/bg-certif-usbn-ipa.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `810px`,
            color: `#4B4B4B`,
          }">
            <div class="style1" v-if="Object.keys(data).length > 0">
              <div 
                style="
                  font-style: normal;
                  text-align: center;
                  font-family: Inter, sans-serif;
                  font-weight: 600;
                  font-size: 40px;
                  line-height: auto;
                  margin-top: 22%;
                  color: #3A3A3A;
                ">
                {{ data.user_name }}
              </div>
              <div 
                style="
                  font-style: normal;
                  text-align: center;
                  font-family: Inter, sans-serif;
                  font-weight: 600;
                  font-size: 20px;
                  letter-spacing: 2px;
                  line-height: auto;
                  margin-top: 9%;
                  color: #343B6D;
                  /* color: #272E66; */
                ">
                {{ endDateTryoutTest }}
              </div>
            </div>
          </section>
        </template>
        <template v-if="data.program_name.trim() === '[Try Out] Sukses US SMA IPS 2025 - Paket 1'">
          <section class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/bg-certif-usbn-ips.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `810px`,
            color: `#4B4B4B`,
          }">
            <div class="style1" v-if="Object.keys(data).length > 0">
              <div 
                style="
                  font-style: normal;
                  text-align: center;
                  font-family: Inter, sans-serif;
                  font-weight: 600;
                  font-size: 40px;
                  line-height: auto;
                  margin-top: 22%;
                  color: #3A3A3A;
                ">
                {{ data.user_name }}
              </div>
              <div 
                style="
                  font-style: normal;
                  text-align: center;
                  font-family: Inter, sans-serif;
                  font-weight: 600;
                  font-size: 20px;
                  letter-spacing: 2px;
                  line-height: auto;
                  margin-top: 9%;
                  color: #343B6D;
                  /* color: #272E66; */
                ">
                {{ endDateTryoutTest }}
              </div>
            </div>
          </section>
        </template>
      </section>
      <section slot="pdf-content" v-else>
        <section class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/bg-new-certif.jpg')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `810px`,
          color: `#4B4B4B`,
        }">
          <div class="style1" v-if="Object.keys(data).length > 0">
            <div 
              style="
                font-style: normal;
                text-align: center;
                font-family: Urbanist, sans-serif;
                font-weight: 900;
                font-size: 50px;
                line-height: auto;
                margin-top: 22%;
                color: black;
              ">
              {{ data.user_name }}
            </div>
          <!-- <div 
              style="
                font-style: normal;
                font-family: Urbanist, sans-serif;
                font-weight: 800;
                font-size: 20px;
                text-align: center;
                line-height: 20px;
                margin-top: 10dvh;
                color: black;
              ">
               Periode {{ certificatePeriode(data.created_at) }}
            </div> -->

            <div 
              style="
                font-style: normal;
                font-family: Inter, sans-serif;
                font-weight: 400;
                font-size: 18px;
                text-align: center;
                line-height: 20px;
                margin-top: 11%;
                margin-left: 3.5%;
                color: black;
              ">
              {{ converDatetime(data.end_test_at) }}
            </div>
          </div>
        </section>
      </section>
    </vue-html2pdf>
  </v-card>
</template>

<script>
import { LMS_API } from "@/constants/api"

import moment from "moment";
import "moment/locale/id";
import VueHtml2pdf from "vue-html2pdf";
// import VueQrcode from '@chenfengyuan/vue-qrcode';
// import { mapGetters } from "vuex";

export default {
  name: "certif-list",
  components: { 
    VueHtml2pdf, 
    // VueQrcode 
  },
  data() {
    return {
      certificateName: '',
      dialog: true,
      showbtn: false,
      false: false,
      output: null,
      tampil: false,
      is_prakerja: null,
      data: {},
      test: '10',
      member: {},
      program: '',
      competence: [],
      result_score: {},
      dialogCertif: false,
      styleObject: {
        paddingTop: '100px',
        paddingBottom: '180px'
      },
      styleObject2: {
        paddingTop: '100px',
        paddingBottom: '100px'
      },
      styleObject3: {
        paddingTop: '100px',
        paddingBottom: '80px'
      },
      timer: '',
      score: '',
      dateFinish: [],
      tgl: null
    };
  },
  computed: {
    showDigimind() {
      const programs = [
        'Mengembangkan Konten di Media Sosial Untuk Menjadi Spesialis Pemasaran Digital',
        'Melakukan Riset Kata Kunci dan Optimasi SEO Website Untuk Menjadi Manajer Pemasaran',
        'Merencanakan Strategi Pemasangan Iklan di Media Sosial Untuk Menjadi Manajer Periklanan'
      ]
      return programs.includes(this.data.program_name)
    },
    showIoa () {
      const programs = [
        'Mengolah Data Penjualan dengan Microsoft Excel untuk Menjadi Admin Input Data',
        'Mengelola Kinerja Karyawan Untuk Menjadi Manajer Sumber Daya Manusia',
        'Melakukan Komunikasi Bahasa Inggris Melalui Panggilan Telepon Bagi Calon Petugas Informasi Pusat Kontak',
        'Merumuskan Kebijakan Organisasi dan Strategi Pengelolaan SDM Untuk Menjadi Manajer Sumber Daya Manusia'
      ]
      return programs.includes(this.data.program_name)
    },
    showAdgi() {
      const programs = [
        'Desain Logo Dan Konten Untuk Desainer Grafis',
        'Merancang Desain Konten Untuk Desainer Grafis'
      ]

      return programs.includes(this.data.program_name)
    },
    convertMonth() {
      return moment(this.data.end_test_at).locale("id").format("MM")
    },
    convertYear() {
      return moment(this.data.end_test_at).locale("id").format("YYYY")
    },
    EndDateTestTDS() {
      return moment(this.data.end_test_at).locale("id").format("DD MMMM YYYY")
    },
    endDateTryoutTest() {
      return moment(this.data.end_test_at).locale("id").format("DD MMMM YYYY")
    }
  },
  created() {
    this.getData();
    // this.timer = setInterval(this.getData, 2000)
  },
  mounted() {
  },
  methods: {
    async getData() {
      this.false = true;
      await this.axios
        .get(`${LMS_API.MEMBER.ORDER.DETAIL_ACTIVITY_SCORE(this.$route.params.license)}`)
        .then((response) => {
          let res = response.data.data;
          this.result_score = res
          // this.countAverage()
        })
      await this.axios
        .get(`${LMS_API.MEMBER.ORDER.DETAIL_CERTIFICATE(this.$route.params.license)}`, { headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY } })
        .then((response) => {
          let res = response.data.data;
          this.data = res;
          this.certificateName = res.user_name + ' - ' + res.program_name
          setTimeout(() => {
            this.showCertif();
          }, 200)
        });
    },
    cancelAutoUpdate() { clearInterval(this.timer) },
    reloadPage() {
      window.location.reload();
      this.showbtn = true;
    },
    certificatePeriode(created_at) {
      return moment(created_at).locale("id").format("MMMM YYYY") + " - " + moment(created_at).locale("id").add(10, "M").format("MMMM YYYY");
    },
    converDatetime(created_at) {
      return moment(created_at).locale("id").format("DD MMMM YYYY");
    },
    currentDateTime(a) {
      return moment(a).locale("nl").format("L");
    },
    currentDateTimes(a) {
      return moment(a).locale("nl").format("L");
    },
    dateBirthTest(a) {
      return a;
    },
    convertHour(val) {
      let minutes = 0
      let hours = 0
      let last = ''
      if (val > 60) {
        hours = val / 60
        minutes = val % 60
        if (minutes > 0) {
          last = parseInt(hours) + ' Jam ' + minutes + ' Menit'
        } else {
          last = hours + ' Jam '
        }
      } else {
        minutes = val
        last = minutes + ' Menit'
      }
      return last
    },
    dateExpired(a) {
      let currentDate = moment(a);
      let futureYear = moment(currentDate).add(1, "Y");
      return moment(futureYear).format("MMMM D, YYYY");
    },
    updateCertif(id) {
      let order_id = this.details.id;
      let sertificate_id = id;

      this.axios
        .put(
          `${LMS_API.MEMBER.ORDER.UPDATE_CERTIFICATE}`,
          { order_id, sertificate_id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // let res = response.data.data;
          this.loading = false;
          if (response.status == 200) {
            this.loading = false;
            // this.members = res;
            this.getDetailCertif(sertificate_id);
            // console.log("berhasil", res);
          }
        });
    },
    hasGenerated($event) {
      console.log($event);
    },
    getDetail(order) {
      this.id = order;
      this.axios
        .get(`${LMS_API.PROGRAM.DETAIL(this.id)}`)
        .then((response) => {
          // this.loading = false;
          let res = response.data.data;
          // this.loading = false;
          this.detail = res.program;
          // console.log('data detail', this.detail)
          this.showCertif(this.id);
        });
    },
    showCertif() {
      this.$refs.certifPeserta.generatePdf();
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
};
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css?family=Saira+Condensed:700"); */
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

tr:nth-child(even) {
  background-color: #f9fafc;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
  border-bottom: 0px solid transparent !important;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  border-bottom: 0px solid transparent !important;
  font-size: 14px;
  font-weight: 600;
  color: #8492a6;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr {
  color: #3c4858;
}

.theme--light.v-data-table .v-data-footer {
  border-top: 0px solid transparent !important;
  padding: 16px !important;
}

.v-data-footer__select {
  justify-content: left !important;
  position: absolute;
  left: 20px;
}

.vue-html2pdf tr:nth-child(even) {
  background-color: #fff !important;
}

.style1 {
  padding-top: 100px;
  padding-bottom: 188px
}

.style2 {
  padding-top: 100px;
  padding-bottom: 180px;
}

.style3 {
  padding-top: 100px;
  padding-bottom: 148px;
}

.style4 {
  padding-top: 100px;
  padding-bottom: 116px;
}

.style5 {
  padding-top: 100px;
  padding-bottom: 0px;
}

@media print {
  @page {
    size: landscape;
  }
}
</style>