<template>
  <div 
    v-if="user_fullname"
    style="
      margin-top: 2rem;
      margin-bottom: 2rem;
      border: 1px solid #E5E7EB; 
      border-radius: 10px;
      font-family: 'Inter', sans-serif;">
    <div
      style="
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 10px 20px
      ">
      <div @click="convert">
        <img
          width="120"
          height="120"
          style="
            object-fit: cover;
            border-radius: 50%;
            margin-top: 20px;
          "
          :src="image"/>
          <!-- :src="use  r_sistem.talent_photo_url"/> -->
      </div>
      <div 
        style="
          display: flex;
          justify-content: start;
          align-items: start;
          gap: 15px;
          padding: 20px;
        ">
        <div>
          <div style="font-weight: 600; font-size: 26px">{{ user_fullname }}</div>
          <div style="display: flex; justify-content: start; align-items: center; font-size:12px; gap: 15px; margin: 1rem 0;">
            <div style="display: flex; align-items: center;">
              <img src="@/assets/images/icon/user-outline.svg" alt="">
              <div class="ml-1">{{ user_ages }} Tahun</div>
            </div>
            <div style="display: flex;align-items: center;">
              <img src="@/assets/images/icon/dna-outline.svg" alt="">
              <div class="ml-1">{{ user_sistem.talent_gender ? user_sistem.talent_gender : "-"  }}</div>
            </div>
          </div>
          <div style="display: flex; justify-content: start; align-items: center; gap: 10px; font-size: 10px; margin-top: 1rem;">
            <div style="display: flex;align-items: center;">
              <div>Email</div>
              <div class="ml-1"> : {{ user_sistem.talent_email }}</div>
            </div>
            <div style="display: flex;align-items: center;">
              <div>No Telp</div>
              <div class="ml-1"> : {{ user_sistem.talent_phone ? user_sistem.talent_phone : "xxxx-xxxx-xxxx" }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'AccountResumeDataDiri',
  data() {
    return {
      image: null,
    }
  },
  computed: {
    ...mapGetters(['user_sistem']),
    user_fullname() {
      return this.user_sistem.talent_first_name + ' ' + this.user_sistem.talent_last_name
    },
    user_ages() {
      let dob = new Date(moment(this.user_sistem.talent_birth_date).format("MM/DD/YYYY"));

      //calculate month difference from current date in time
      let month_diff = Date.now() - dob.getTime();

      //convert the calculated difference in date format
      let age_dt = new Date(month_diff); 
      
      //extract year from date    
      let year = age_dt.getUTCFullYear();
      
      //now calculate the age of the user
      let age = Math.abs(year - 1970);

      return age

    },
  },
  mounted() {
    this.convert();
  },
  methods: {
    convertToBase64FromUrl(url) {
      // === Without fetch ===
      // return new Promise((resolve, reject) => {
      //   const img = new Image();
      //   img.crossOrigin = 'Anonymous'; // Enables CORS
      //   img.src = url;

      //   img.onload = () => {
      //       const canvas = document.createElement('canvas');
      //       canvas.width = img.width;
      //       canvas.height = img.height;

      //       const ctx = canvas.getContext('2d');
      //       if (ctx) {
      //           ctx.drawImage(img, 0, 0);

      //           const dataURL = canvas.toDataURL('image/png');
      //           resolve(dataURL);
      //       } else {
      //           reject(new Error("Failed to get canvas context"));
      //       }
      //   };

      //   img.onerror = (error) => {
      //       console.error("Image loading error:", error);
      //       reject(error);
      //   };
      // });

      // === Using fetch ===
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
          var reader = new FileReader();
          reader.onloadend = function () {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = () => {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      });
    },

    convert() {
      this.convertToBase64FromUrl(this.user_sistem.talent_photo_url).then((result) => {
        this.image = result;
      });
      // this.image = this.user_sistem.talent_photo_url;
    }
  }
}
</script>