<template>
  <div class="text-center pa-4">
    <v-icon color="red" size="300">ri-alert-fill</v-icon>
    <div class="fs-32 mb-5 text-center font-weight-bold">Test telah dihentikan</div>
    <div class="fs-18 text-center">
      <span>Anda telah terindikasi melakukan kecurangan dengan membuka tab baru sebanyak 3x, sesuai dengan syarat dan ketentuan yang berlaku maka tes anda kami hentikan.</span>
    </div>

    <div>
      Anda akan diarahkan kembali menuju halaman beranda anda dalam <span class="font-weight-bold">{{ countTimer }} detik</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Disqualified",
  data() {
    return {
      countTimer: 10
    }
  },
  mounted(){
    this.countDown();
  },
  methods: {
    countDown(){
      let counter = setInterval(() => {
         
        console.log(this.countTimer);
        
        if (this.countTimer === 0) {
          clearInterval(counter);
          this.$router.push({ path: '/class'})
          window.location.reload(true);
          return;
        }

        this.countTimer--;
      }, 1000);
      
      
    }
  }
};
</script>