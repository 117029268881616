<template>
  <v-row>
    <v-col cols="12">
      <v-container>
        <v-breadcrumbs :items="breadcrumbs" >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

        <Snackbar ref="snackbar" />

        <v-card outlined flat class="rounded-lg" style="position: relative;">
          <v-img
            class="align-end"
            style="height: 200px; width: 100%;"
            :src="require('@/assets/images/company_layer.svg')">
          </v-img>
          <v-card-text 
            class="d-flex justify-space-between align-end black--text mb-3"
            :style="$vuetify.breakpoint.name == 'xs' ? 'height: 250px;width: 100%;' : 'height: 90px;width: 100%;'">

            <div 
              class="d-flex justify-start align-end" 
              :class="$vuetify.breakpoint.name == 'xs' ? 'flex-column' : ''"
              style="position: static; top: 30%; z-index: 1; width: 100%; flex: 1;">
              <img
                v-if="company.company_logo_url"
                width="150"
                class="mx-auto rounded-circle"
                :src="company.company_logo_url"/>
              <v-avatar 
                color="primary"
                class="fs-16 white--text font-weight-medium"
                size="45"
                v-else>
                {{ initialName(company.company_name) }}
              </v-avatar>
              <div class="ml-3" style="width: 100%; flex: 1;">
                <div class="fs-18 font-weight-bold" :class="$vuetify.breakpoint.name == 'xs' ? 'text-center mt-1 ' : 'mb-1'">{{ company.company_name }}</div>
                <v-row no-gutters>
                  <v-col cols="12" md="4" lg="4" class="d-flex align-center mb-1" :class="$vuetify.breakpoint.name == 'xs' ? 'justify-center' : 'justify-start'">
                    <v-icon class="mr-2" color="black" size="16">mdi-information-slab-circle-outline</v-icon>
                    <div class="fs-12">{{ company.company_business_industry.label ? company.company_business_industry.label : "-" }}</div>
                  </v-col>
                  <v-col cols="12" md="4" lg="4" class="d-flex align-center mb-1" :class="$vuetify.breakpoint.name == 'xs' ? 'justify-center' : 'justify-start'">
                    <v-icon class="mr-2" color="black" size="16">mdi-account-outline</v-icon>
                    <div class="fs-12">Karyawan: {{ company.company_number_of_employee.label ? company.company_number_of_employee.label + " orang" : "-" }}</div>
                  </v-col>
                  <v-col cols="12" md="4" lg="4" class="d-flex align-center mb-1" :class="$vuetify.breakpoint.name == 'xs' ? 'justify-center' : 'justify-start'">
                    <v-icon class="mr-2" color="black" size="16">ri-map-pin-line</v-icon>
                    <div class="fs-12">{{ company.company_address.address_province ? company.company_address.address_province.province_name : "-" }}</div>
                  </v-col>
                </v-row>

                <div v-if="$vuetify.breakpoint.name == 'xs'" class="d-flex flex-column justify-center align-center text-end mt-2">
                  <v-btn
                    class="text-none mb-1"
                    elevation="0"
                    x-small
                    text>
                    <v-icon size="16" color="black" left>ri-share-line</v-icon>
                    Bagikan Perusahaan
                  </v-btn>
                  <v-btn
                    x-small
                    depressed
                    color="primary"
                    class="rounded-lg"
                    @click="() => { $router.push({name: 'jobs', params: { companyId: company.company_id }}) }">
                    Lihat Lowongan Kerja
                  </v-btn>
                </div>

              </div>
            </div>
            
            <div v-if="$vuetify.breakpoint.name != 'xs'" class="d-flex justify-end align-center text-end" style="gap:5px; width: 30%;">
              <v-btn
                class="text-none"
                elevation="0"
                icon
                x-small
                text>
                <v-icon size="16" color="black">ri-share-line</v-icon>
              </v-btn>
              <v-btn
                x-small
                depressed
                color="primary"
                class="rounded-lg"
                @click="() => { $router.push({name: 'jobs', params: { companyId: company.company_id }}) }">
                Lihat Lowongan Kerja
              </v-btn>
            </div>

          </v-card-text>
        </v-card>

        <v-card outlined flat class="rounded-lg mt-5">
          <v-card-text class="black--text">
            <div class="fs-18 font-weight-bold">Tentang Solutionlabs Grup</div>
            <div class="fs-12 mt-2 text-gray-700 font-weight-regular" v-html="company.company_description"></div>

            <div class="fs-18 font-weight-bold my-4">Informasi Perusahaan</div>

            <div class="d-flex justify-start" :class="$vuetify.breakpoint.name == 'xs' ? 'flex-column' : ''">
              <div style="width: 200px;" class="font-weight-bold text-gray-700 fs-14">Alamat</div>
              <div class="fs-12 font-weight-regular text-gray-700">
                {{ company.company_address.address_address_line ? company.company_address.address_address_line + ", " : "" }} 
                {{ company.company_address.address_province ? company.company_address.address_province.province_name + ", " : "" }}
                {{ company.company_address.address_district ? company.company_address.address_district.district_name + ", " : "" }}
                {{ company.company_address.address_sub_district ? company.company_address.address_sub_district.subdistrict_name : "" }}
              </div>
            </div>
            <div class="d-flex justify-start my-4" :class="$vuetify.breakpoint.name == 'xs' ? 'flex-column' : ''">
              <div style="width: 200px;" class="font-weight-bold text-gray-700 fs-14">Website Perusahaan</div>
              <div class="fs-12 font-weight-regular text-gray-700">
                <a :href="company.company_telecom.telecom_url" target="_blank">{{ company.company_telecom.telecom_url }}</a>
              </div>
            </div>
            <div class="d-flex justify-start" :class="$vuetify.breakpoint.name == 'xs' ? 'flex-column' : ''">
              <div style="width: 200px;" class="font-weight-bold text-gray-700 fs-14">Email Perusahaan</div>
              <div class="fs-12 font-weight-regular text-gray-700">{{ company.company_telecom.telecom_email }}</div>
            </div>
          </v-card-text>
        </v-card>

        <p class="text-h6 my-5">Postingan Lowongan Pekerjaan</p>

        <v-row>
          <v-col 
            cols="12" md="4" lg="4"
            v-for="(job, i) in jobs" :key="i">
            <v-card 
              class="rounded-lg px-4 mb-3"
              :style="
                job.job_id == company.job_id ? 'border: 1px solid #1C64F2' : ''
              " 
              outlined>
              <v-card-title class="px-0">
                <div class="font-weight-bold fs-20">{{ job.job_title }}</div>
                <v-spacer></v-spacer>
                <v-btn
                  class="text-none"
                  elevation="0"
                  icon
                  x-small
                  text
                  @click="sharejobs(job)">
                  <v-icon size="16" color="black">ri-share-line</v-icon>
                </v-btn>
                <v-btn
                  class="text-none"
                  elevation="0"
                  icon
                  x-small
                  text
                  @click="saveToBookmark(job)">
                  <v-icon size="16" :color="job.job_talent_bookmarks.length > 0 ? 'primary' : 'black'"> {{ job.job_talent_bookmarks.length > 0 ? 'ri-bookmark-fill' : 'ri-bookmark-line' }}</v-icon>
                </v-btn>

              </v-card-title>
              <v-card-text class="px-0" @click="goToDetail(job)">

                <div class="d-flex" :class="$vuetify.breakpoint.name == 'xs' ? 'flex-column justify-start align-start ' : 'justify-space-between align-center '" style="width: 100%;">
                  <div class="d-flex justify-center align-center" style="gap: 5px">
                    <img 
                      v-if="job.job_company.company_logo_url"
                      :src="job.job_company.company_logo_url" 
                      alt="logo"
                      class="rounded-circle mr-3"
                      width="50">
                      <v-avatar 
                        color="primary"
                        class="caption white--text font-weight-medium"
                        size="20"
                        v-else>
                        {{ initialName(job.job_company.company_name) }}
                      </v-avatar>
                    <div class="font-weight-bold fs-12 text-gray-900">{{ job.job_company.company_name }}</div>
                  </div>
                </div>
                <div class="d-flex justify-start align-center" :class="$vuetify.breakpoint.name == 'xs' ? 'fs-10' : 'fs-14'">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12.0002 14C11.2091 14 10.4357 13.7654 9.77795 13.3259C9.12015 12.8863 8.60746 12.2616 8.30471 11.5307C8.00196 10.7998 7.92275 9.99554 8.07709 9.21961C8.23143 8.44369 8.61239 7.73096 9.1718 7.17155C9.73121 6.61214 10.4439 6.23117 11.2199 6.07683C11.9958 5.92249 12.8001 6.0017 13.531 6.30445C14.2619 6.60721 14.8866 7.11989 15.3261 7.77769C15.7656 8.43549 16.0002 9.20885 16.0002 9.99997C16.0002 11.0608 15.5788 12.0783 14.8287 12.8284C14.0785 13.5785 13.0611 14 12.0002 14ZM12.0002 7.99997C11.6047 7.99997 11.218 8.11727 10.8891 8.33703C10.5602 8.5568 10.3038 8.86915 10.1525 9.23461C10.0011 9.60006 9.96149 10.0022 10.0387 10.3902C10.1158 10.7781 10.3063 11.1345 10.586 11.4142C10.8657 11.6939 11.2221 11.8844 11.61 11.9615C11.998 12.0387 12.4001 11.9991 12.7656 11.8477C13.131 11.6964 13.4434 11.44 13.6632 11.1111C13.8829 10.7822 14.0002 10.3955 14.0002 9.99997C14.0002 9.46954 13.7895 8.96083 13.4144 8.58576C13.0394 8.21069 12.5307 7.99997 12.0002 7.99997Z" fill="#6B7280"/>
                    <path d="M12.0002 22C11.8526 21.9999 11.7068 21.9671 11.5733 21.904C11.4398 21.8409 11.322 21.749 11.2282 21.635L5.98323 15.261C5.88323 15.149 5.76923 15.009 5.65723 14.861L5.53723 14.715C5.49065 14.6585 5.45041 14.5972 5.41723 14.532C4.59355 13.3376 4.11017 11.9418 4.01881 10.4939C3.92745 9.04595 4.23156 7.60043 4.89858 6.31204C5.5656 5.02366 6.57043 3.94091 7.80551 3.1797C9.04059 2.4185 10.4594 2.00748 11.9101 1.99065C13.3608 1.97382 14.7888 2.35181 16.0412 3.08416C17.2936 3.81652 18.3233 4.87566 19.02 6.14822C19.7168 7.42079 20.0543 8.85886 19.9966 10.3085C19.9388 11.7582 19.488 13.1649 18.6922 14.378C18.6596 14.4467 18.6186 14.5112 18.5702 14.57L18.4432 14.725C18.2645 14.9695 18.0715 15.2032 17.8652 15.425L12.7732 21.63C12.6799 21.7451 12.5621 21.838 12.4284 21.902C12.2947 21.966 12.1484 21.9994 12.0002 22ZM7.17523 13.562L7.22223 13.619C7.32223 13.754 7.41523 13.861 7.50723 13.965L12.0002 19.425L16.3582 14.117C16.5373 13.9241 16.7043 13.7204 16.8582 13.507L16.9332 13.416L16.9592 13.375C17.5778 12.468 17.9356 11.4088 17.9938 10.3125C18.0521 9.21626 17.8084 8.12505 17.2894 7.15769C16.7704 6.19034 15.9959 5.38391 15.0503 4.82622C14.1048 4.26853 13.0243 3.98096 11.9266 3.99481C10.8289 4.00866 9.75601 4.32341 8.8248 4.90478C7.89359 5.48615 7.13972 6.31186 6.64529 7.292C6.15086 8.27215 5.93481 9.36916 6.02067 10.4636C6.10652 11.558 6.491 12.6079 7.13223 13.499C7.14823 13.5203 7.16256 13.5413 7.17523 13.562Z" fill="#6B7280"/>
                  </svg>
                  <div class="text-capitalize fs-12 font-weight-regular text-gray-700">{{ job.job_province.province_name }}</div>
                </div>

                <v-divider class="my-4"></v-divider>

                <v-row class="mb-3" no-gutters>
                  <v-col cols="4" class="d-flex align-center mb-3">
                    <v-icon class="mr-2" color="#6B7280" size="16">mdi-briefcase-variant-outline</v-icon>
                    <div class="fs-12 font-weight-medium text-gray-900 text-truncate">{{ job.job_category?.label ? job.job_category?.label : "-" }}</div>
                  </v-col>
                  <v-col cols="4" class="d-flex align-center mb-3">
                    <v-icon class="mr-2" color="#6B7280" size="16">mdi-clock-time-four-outline</v-icon>
                    <div class="fs-12 font-weight-medium text-gray-900">Fulltime</div>
                  </v-col>
                  <v-col cols="4" class="d-flex align-center">
                    <v-icon class="mr-2" color="#6B7280" size="16">mdi-home-outline</v-icon>
                    <div class="fs-12 font-weight-medium text-gray-900">{{ job.job_type_of_workplace?.label ? job.job_type_of_workplace?.label : "-"  }}</div>
                  </v-col>
                  <v-col cols="4" class="d-flex align-center">
                    <v-icon class="mr-2" color="#6B7280" size="16">mdi-account-outline</v-icon>
                    <div class="fs-12 font-weight-medium text-gray-900">{{ job.job_type_of_experience?.label ? job.job_type_of_experience?.label : "-" }}</div>
                  </v-col>
                  <v-col cols="4" class="d-flex align-center">
                    <v-icon class="mr-2" color="#6B7280" size="16">mdi-calendar-month-outline</v-icon>
                    <div class="fs-12 font-weight-medium text-gray-900">1-2 tahun</div>
                  </v-col>
                  <v-col cols="4" class="d-flex align-center">
                    <v-icon class="mr-2" color="#6B7280" size="16" style="rotate: 90deg;">mdi-tag-outline</v-icon>
                    <div v-if="job.job_salary_confidential" class="fs-12 font-weight-medium text-gray-900 text-truncate">{{ job.job_salary }}</div>
                    <div v-else class="fs-12 font-weight-medium text-gray-900 text-truncate">{{ job.job_salary_min | pricing }} {{ " - " }} {{ job.job_salary_max | pricing }}</div>
                  </v-col>
                </v-row>

                <!-- <v-chip
                  v-for="tag in ['Fulltime', 'Remote']"
                  :key="tag"
                  label
                  small
                  color="#F3F4F6"
                  class="rounded-lg mr-1 font-weight-medium"
                >
                  {{ tag }}
                </v-chip> -->

              </v-card-text> 
            </v-card>
          </v-col>
        </v-row>

      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { JOB_PORTAL_API } from "@/constants/api";

import Snackbar from "@/components/Snackbar";

export default {
  name: "CompanyDetail",
  components: {
    Snackbar
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Perusahaan",
        disabled: false,
        href: "/company",
      },
      {
        text: "Detail Perusahaan",
        disabled: true,
        href: "/company/detail",
      },
    ],
    jobs: [],
    company: {},
  }),
  computed: {
    initialName() {
      return (company_name) => {
        let firstAlias = "";
        let secondAlias = "";
          let name = company_name;
          let splitname = name.split(" ");
  
          if (splitname[0]) {
            firstAlias = splitname[0].substring(0, 1);
          }
  
          if (splitname[1]) {
            secondAlias = splitname[1].substring(0, 1);
          }
        return firstAlias + secondAlias;
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0, { behavior: 'smooth' });

    this.fetchDetail()
  },
  methods: {
    async fetchDetail() {
      try {
        const response = await this.axios.get(`${JOB_PORTAL_API.COMPANY.DETAIL(this.$route.params.id)}`);
        this.company = response.data;
        this.fetchJobs()
      } catch (error) {
        console.log(error);
      }
    },

    async fetchJobs() {

      let params = {
        page: 1,
        limit: 3,
      }

      params['filter.job_company.company_id'] = this.$route.params.id

      const response = await this.axios.get(`${JOB_PORTAL_API.JOBS.GET_LIST}`, {
        params
      })

      const res = response.data.data

      this.jobs = res
    },

    async saveToBookmark(job) {
      // console.log(job);

      let URL_API = `${JOB_PORTAL_API.LOWONGAN.BOOKMARK.CREATE(job.job_id)}`
      let STATUS = 'create'

      if (job.job_talent_bookmarks.length > 0) {
        URL_API = `${JOB_PORTAL_API.LOWONGAN.BOOKMARK.DELETE(job.job_id)}`
        STATUS = 'delete'
      }

      await this.axios.post(`${URL_API}`)
      .then(() => {

        this.$refs.snackbar.open('primary',`${STATUS == 'create' ? 'Berhasil menambahkan lowongan ke' : 'Berhasil menghapus lowongan dari'} bookmark`)

        this.fetchJobs()
      }).catch(() => {
        this.$refs.snackbar.open('error', 'Gagal menambahkan lowongan ke bookmark')
      })
    },

    sharejobs(job) {
      // create copy text to clipboard
      navigator.clipboard.writeText(window.location.origin + '/jobs/' + job.job_id)

      this.$refs.snackbar.open('primary', 'Berhasil menyalin link lowongan')
    },

    goToDetail(job) {
      this.company = job;
      this.$router.push({ name: "jobs-detail", params: { id: job.job_id } });
    },
  }
}
</script>